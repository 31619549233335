import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { combineLatest, Observable } from 'rxjs'
import {
  selectBreakdownLocationCoordinates,
  selectBreakdownMarker,
  selectCityStateAndZip,
  selectGpsLocationDenied,
  selectHasGPSAccess,
  selectLandMarkAndStreet
} from '../location.selectors'
import { GenericCoordinates, GoogleLocationMarker, LocationWithMarker, MapState, } from '../location.types'
import { DEFAULT_BREAKDOWN_MARKER, MessageDialogTypes } from '../../ui/ui.types'
import { closeDialog } from '../../ui/ui.actions'
import { AbstractResponsiveComponent } from '../../../shared/abstract-responsive.component'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { filter, map } from 'rxjs/operators';
import { GoogleCoordinates } from '../google-geocode/types';
import {
  LOCATION_TYPE,
  resetBreakdownLocation,
  SET_BREAKDOWN_LOCATION,
  setBreakdownLocationRequest
} from '../location.actions';
import { ZoneEventEmitter } from '../../../zone-event-emitter'
import { selectIsLoading } from '../../ui/loading/loading.selectors';
import { GoogleGeocodeService } from '../google-geocode/google-geocode.service';
import { guessLocationFromCoordsResults } from '../google-geocode/google-geocode.utils';
import { googleLocationToAAALocation } from '../../../shared/utils';
import { concatAddressLine1, concatAddressLine2 } from '../../../shared/utils/concatAddress';
import { requestSearchArea } from '../aar/aar.actions'
import {
  selectAarPreviewLocation,
  selectIsEVstation,
  selectIsLoadingSearchArea,
  selectTowLocationSearch
} from '../aar/aar.selectors'
import { aarAppendLocationMarker, LocationUtils } from '../location.utils'
import { selectTowLocationPreview } from '../tow-location/tow-location.selectors'
import { AARData, FacilitiesDisplay } from '../aar/aar.types'
import { setAARAddress } from '../tow-location/tow-location.actions'
import { NO_FACILITIES_REQUEST_AREA_DURATION } from '../location.constants';
import { selectIsRapUser } from '../../auth/auth.selectors';
import { MapViews } from '../map/map.component';

const DESTINATIONS = () => $localize`Destinations`
const AUTO_REPAIRS = () => $localize`Auto Repairs`
const ADJUST_LOCATION = () => $localize`Adjust Location`
const ADJUST_YOUR_LOCATION = () => $localize`Adjust your location`
const DONE = () => $localize`Done`
const CONFIRM_LOCATION = () => $localize`Confirm Location`
const DIALOG_LIST_AAA_FACILITIES = () => $localize`List of AAA Facilities`
const DIALOG_LIST_DESTINATIONS = () => $localize`List of Destinations`

@Component({
  selector: 'app-map-modal',
  templateUrl: './map-modal.component.html',
  styleUrls: ['./map-modal.component.scss'],
  animations:[
    trigger('slideInOut', [
      state('in', style({height: '0'})),
      transition(':leave', [
        style({height: '*'}),
        animate('300ms ease-in-out', style({height: 0}))
      ]),
      transition(':enter', [
        style({height: '0'}),
        animate('500ms ease-in-out', style({height: '*'}))
      ])
    ])
  ]
})
export class MapModalComponent
  extends AbstractResponsiveComponent
  implements OnInit {

  @Input() isOpen = false
  @Input() userCoords
  @Input() selectedShop: GoogleLocationMarker
  @Input() view: string = MapViews.BREAKDOWN_LOCATION
  @Input() loadAddress = false
  @Input() useSearchArea = false
  @Input() popupMode = true
  @Input() get needMoreInfo(): Boolean {
    return this._needMoreInfo
  }
  set needMoreInfo(flag) {
    this._needMoreInfo = flag
    this.needMoreInfoBlocked = flag
  }
  _needMoreInfo: Boolean = false

  @Output() closeMapModalClick: EventEmitter<any>
  @Output() useCurrentLocation = new EventEmitter()
  @Output() onShopDetailsClose: EventEmitter<void> = new EventEmitter()

  loading: Boolean = false
  location: GoogleCoordinates
  lastSearchCoordinates: GenericCoordinates
  showSearchArea: Boolean = false
  addressLine1: string
  addressLine2: string
  displaySelectedTowLocation = false
  selectedTowLocation: AARData
  noFacilitiesRequestAreaDuration = NO_FACILITIES_REQUEST_AREA_DURATION
  isRapUser = false
  needMoreInfoBlocked: Boolean = false
  isAddressSetByUser: Boolean = false

  get headingTitle(): string {
    return this.view === MapViews.TOWING_DESTINATION
      ? (this.isRapUser ? DESTINATIONS() : AUTO_REPAIRS())
      : (!this.needMoreInfo ? ADJUST_LOCATION() : ADJUST_YOUR_LOCATION())
  }

  get listButtonLabel(): string {
    return this.isRapUser ? DIALOG_LIST_DESTINATIONS() : DIALOG_LIST_AAA_FACILITIES()
  }

  get confirmLocationLabel(): string {
    return this.needMoreInfo ? CONFIRM_LOCATION() : DONE()
  }

  expandedResults = false
  _facilitiesDisplay = null
  @Input() get facilitiesDisplay(): FacilitiesDisplay {
    return this._facilitiesDisplay
  }
  set facilitiesDisplay(facilitiesDisplay: FacilitiesDisplay) {
    this._facilitiesDisplay = facilitiesDisplay
    if (facilitiesDisplay.expandedResult) {
      this.expandedResults = true
      setTimeout(() => this.expandedResults = false, this.noFacilitiesRequestAreaDuration)
    }
  }

  selectLandMarkAndStreet$: Observable<string> = this.store$.pipe(
    select(selectLandMarkAndStreet)
  )

  selectCityStateAndZip$: Observable<string> = this.store$.pipe(
    select(selectCityStateAndZip)
  )

  breakdownMarker$: Observable<GoogleLocationMarker> = this.store$.pipe(
    select(selectBreakdownMarker),
    filter((marker) => Boolean(marker))
  )

  towLocationSearch$: Observable<MapState> = this.store$.pipe(
    select(selectTowLocationSearch)
  )

  isRapUser$: Observable<boolean> = this.store$.pipe(
    select(selectIsRapUser)
  )

  breakdownLocationCoordinates$: Observable<GenericCoordinates> = this.store$.pipe(
    select(selectBreakdownLocationCoordinates)
  )

  lastSearchCoordinates$ = combineLatest([
    this.towLocationSearch$,
    this.breakdownLocationCoordinates$,
  ]).pipe(map(([towLocationSearch, breakdownLocationCoordinates]: [MapState, GenericCoordinates]) =>
    towLocationSearch
      ? {
        latitude: towLocationSearch.center.latitude,
        longitude: towLocationSearch.center.longitude
      } as GenericCoordinates
      : breakdownLocationCoordinates
  ))

  isSetBreakdownLocationLoading$: Observable<boolean> = this.store$.pipe(
    select(selectIsLoading(SET_BREAKDOWN_LOCATION.ACTION))
  )

  isEVstation$: Observable<boolean> = this.store$.pipe(
    select(selectIsEVstation)
  )

  isLoadingSearchArea$: Observable<boolean> = this.store$.pipe(
    select(selectIsLoadingSearchArea)
  )

  hasGPSAccess$: Observable<boolean> = this.store$.pipe(
    select(selectHasGPSAccess)
  )
  _hasGPSAccess: boolean

  towLocationPreview$: Observable<LocationWithMarker> = this.store$.pipe(
    select(selectTowLocationPreview)
  )

  selectedTowLocation$: Observable<AARData> = this.store$.pipe(
    select(selectAarPreviewLocation)
  )

  hasDeniedGpsAccess$: Observable<boolean> = this.store$.pipe(select(selectGpsLocationDenied))

  constructor(
    private store$: Store<AAAStore>,
    private ngZone: NgZone,
    private _geocodeService: GoogleGeocodeService,
    private locationUtils: LocationUtils,
  ) {
    super()
    this.closeMapModalClick = new ZoneEventEmitter<boolean>(ngZone)
  }

  breakdownMarkerRender

  ngOnInit() {
    this.breakdownMarkerRender = {
      ...DEFAULT_BREAKDOWN_MARKER,
      ...(this.view === MapViews.TOWING_DESTINATION ? {offsetY: 40} : {}),
    }
    this.subscriptions.push(
      this.hasGPSAccess$.subscribe((hasGPSAccess) => {
        if (this.needMoreInfo) {
          this._hasGPSAccess = hasGPSAccess
          this.needMoreInfoBlocked = !hasGPSAccess
        }
        if (hasGPSAccess) {
          this.store$.dispatch(
            closeDialog({
              payload: { type: MessageDialogTypes.LOCATION_SERVICES_REQUIRED },
            })
          )
        }
      }),
      this.hasDeniedGpsAccess$.subscribe((hasDeniedGpsAccess) => {
          if (this.needMoreInfo) {
            this._hasGPSAccess = !hasDeniedGpsAccess
            this.needMoreInfoBlocked = hasDeniedGpsAccess
          }
      }),
      this.lastSearchCoordinates$.subscribe((lastSearchCoordinates: GenericCoordinates) =>
        this.lastSearchCoordinates = lastSearchCoordinates
      ),
      this.towLocationPreview$.subscribe(preview => {
        if (preview) {
          this.closeMapModal()
        }
      }),
      this.selectedTowLocation$.subscribe(location => {
        this.selectedTowLocation = location || null
        this.displaySelectedTowLocation = Boolean(this.selectedTowLocation)
      }),
      this.isRapUser$.subscribe(isRapUser => {
        this.isRapUser = isRapUser
      }),
      this.selectLandMarkAndStreet$.subscribe(landMarkAndStreet => {
        this.addressLine1 = landMarkAndStreet
      }),
      this.selectCityStateAndZip$.subscribe(cityStateAndZip => {
        this.addressLine2 = cityStateAndZip
      })
    )
  }

  closeMapModal(done = false) {
    const shouldResetAddress = !done && this.needMoreInfo
    if (shouldResetAddress) {
      this.store$.dispatch(resetBreakdownLocation())
    }
    this.closeMapModalClick.emit(shouldResetAddress)
    this.isOpen = false
    this.loading = false
    this.location = null
    this.addressLine1 = null
    this.addressLine2 = null
    this.needMoreInfo = false
    this.needMoreInfoBlocked = false
    this.isAddressSetByUser = false
  }

  handleSearchArea(mapState: MapState) {
    this.store$.dispatch(requestSearchArea({
      payload: mapState
    }))
    this.showSearchArea = false
  }

  private isSearchAreaAllowed() {
    const distance = this.locationUtils.haversineMiles(this.lastSearchCoordinates, this.location)
    return distance > this.getAvgDistance()
  }

  private getAvgDistance() {
    const totalDistance = this.facilitiesDisplay.markers
      .map((shop) => this.locationUtils.haversineMiles(this.lastSearchCoordinates, shop.marker))
      .reduce((a, b) => a + b, 0)
    return totalDistance / this.facilitiesDisplay.markers.length
  }

  async handleMapDrag(location: GoogleCoordinates) {
    this.isAddressSetByUser = true
    this.expandedResults = false
    this.location = location
    if (this.loadAddress) {
      this.loading = true
      const addressesByCoords = await this._geocodeService.getLocationFromCoords(location)
      const guess = guessLocationFromCoordsResults(
        addressesByCoords,
        location
      )
      const guessedAaaLocation = googleLocationToAAALocation({
        address: guess.formatted_address,
        location: guess,
        locationType: LOCATION_TYPE.ADDRESS_INPUT,
        coords: location
      })
      this.addressLine1 = concatAddressLine1(guessedAaaLocation.streetNumber, guessedAaaLocation.streetName)
      this.addressLine2 = concatAddressLine2(guessedAaaLocation.city, guessedAaaLocation.state, guessedAaaLocation.postalCode, true)
      this.loading = false
    }
    if (this.useSearchArea) {
      this.showSearchArea = this.isSearchAreaAllowed()
    }
  }

  breakdownConfirm() {
    if (this.location) {
      this.store$.dispatch(resetBreakdownLocation())
      this.store$.dispatch(
        setBreakdownLocationRequest({
          payload: this.location,
          meta: { locationType: LOCATION_TYPE.PIN_DROP },
        })
      )
    }
    this.closeMapModal(true)
  }

  assignAarLocation() {
    this.store$.dispatch(
      setAARAddress({
        payload: aarAppendLocationMarker(this.selectedTowLocation),
      })
    )
  }

  adjustPin() {
    this.needMoreInfoBlocked = false
  }

  findMyLocation() {
    this.useCurrentLocation.emit()
    this.isAddressSetByUser = true
    this.needMoreInfoBlocked = false
  }

  isConfirmLocationDisabled() {
    return this.needMoreInfo && (!this.isAddressSetByUser || !this.addressLine1)
  }

  closeShopDetails = () => this.onShopDetailsClose.emit()

}
