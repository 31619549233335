import { WhatHappenedComponent } from './what-happened/what-happened.component'
import { ProblemButtonComponent } from './problem-button/problem-button.component'
import { ProblemChecklistComponent } from './problem-checklist/problem-checklist.component'
import { SituationDetailsComponent } from './situation-details/situation-details.component'
import { SituationEditorComponent } from './situation-editor/situation-editor.component'
import { SituationSummaryComponent } from './situation-summary/situation-summary.component'
import { IssueComponent } from './issue.component'

import { DescriptionNoteComponent } from './description-note/description-note.component'
// import { AccidentComponent } from './accident/accident.component'
import { ProblemTooltipComponent } from './problem-tooltip/problem-tooltip.component'
import { IssueSelectionComponent } from './issue-selection/issue-selection.component'

export function issueComponents() {
  return [
    WhatHappenedComponent,
    ProblemButtonComponent,
    ProblemChecklistComponent,
    SituationDetailsComponent,
    SituationEditorComponent,
    SituationSummaryComponent,
    IssueComponent,
    DescriptionNoteComponent,
    // AccidentComponent,
    ProblemTooltipComponent,
    IssueSelectionComponent
  ]
}
