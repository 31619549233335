import { PayloadedAction } from '../../shared/types'
import {
  RESET_BREAKDOWN_LOCATION,
  RESET_HIGHWAY_EXITS,
  RESET_LAST_SEARCH_LOCATION,
  SET_BREAKDOWN_LOCATION,
  SET_HIGHWAY_EXITS,
  SET_IS_HIGHWAY,
  SET_LANDMARK,
  SET_LAST_SEARCH_LOCATION,
  SET_LOCATION_CLUB,
  SET_LOCATION_DETAILS,
  SET_LOCATION_SERVICES_AVAILABLE,
  SET_LOCATION_STEP,
  SET_LOCATION_TYPE,
  SET_RESET_LOCATION_DENIED,
  SET_SPECIAL_ASSISTANCE,
} from './location.actions'
import { CANCEL_EDITING_REQUEST } from '../../shared/actions/shared.actions'
import {
  BreakdownLocation,
  BreakdownLocationDetails,
  GenericCoordinates,
  HighwayExits,
  LastSearchLocation,
  LOCATION_STEPS,
} from './location.types'

export interface LocationState {
  servicesAvailable: boolean
  gpsLocationDenied: boolean
  geoLocationCoordinates?: GenericCoordinates
  address: string
  landmark: string
  breakdownLocation: BreakdownLocation
  breakdownLocationDetails: BreakdownLocationDetails
  step: LOCATION_STEPS
  selectionType?: string
  displayLandmark?: boolean
  highway: boolean
  highwayExits: HighwayExits
  servicingClub: string
  country?: string
  servicingClubRequested: boolean,
  tempServicingClub?: string
  specialAssistance?: string
  lastSearchLocation?: LastSearchLocation
  towLocationCenter?: {
    latitude: string | number
    longitude: string | number
  }
}

export const initialState: LocationState = {
  servicesAvailable: false,
  gpsLocationDenied: false,
  breakdownLocation: null,
  address: '',
  landmark: '',
  breakdownLocationDetails: {
    options: [],
  },
  step: LOCATION_STEPS.MAP,
  displayLandmark: false,
  highway: false,
  highwayExits: null,
  servicingClub: null,
  servicingClubRequested: false,
  tempServicingClub: null,
  specialAssistance: '',
  lastSearchLocation: null,
}

export function reducer(
  state: LocationState | undefined = initialState,
  action: PayloadedAction
): LocationState {
  switch (action.type) {
    case SET_RESET_LOCATION_DENIED:
      return {
        ...state,
        gpsLocationDenied: false,
      }
    case SET_LOCATION_SERVICES_AVAILABLE:
      return {
        ...state,
        servicesAvailable: action.payload.available,
        geoLocationCoordinates: action.payload.coordinates,
        gpsLocationDenied: action.payload.denied
          ? action.payload.denied
          : state.gpsLocationDenied,
      }
    case SET_BREAKDOWN_LOCATION.SUCCESS: {
      if (!action.payload) {
        return {
          ...state,
          address: '',
          landmark: '',
          breakdownLocation: null,
        }
      }
      return {
        ...state,
        address: action.payload.address,
        breakdownLocation: {
          postalCode: action.payload.postalCode,
          state: action.payload.state,
          city: action.payload.city,
          streetName: action.payload.streetName,
          streetNumber: action.payload.streetNumber,
          zip: action.payload.postalCode,
          latitude: action.payload.latitude,
          longitude: action.payload.longitude,
          locationType: action.payload.locationType,
          landmark: state.landmark,
        },
        towLocationCenter: {
          latitude: action.payload.latitude,
          longitude: action.payload.longitude,
        },
      }
    }
    case SET_BREAKDOWN_LOCATION.REQUEST:
    case RESET_BREAKDOWN_LOCATION:
      return {
        ...state,
        address: '',
        landmark: '',
        displayLandmark: false,
        breakdownLocation: null,
        highwayExits: null,
        servicingClub: null,
        step: LOCATION_STEPS.MAP,
        towLocationCenter: null,
      }
    case SET_LOCATION_DETAILS:
      return {
        ...state,
        breakdownLocationDetails: {
          notes: action.payload.notes || '',
          options: action.payload.options || [],
        },
      }
    case SET_LANDMARK:
      // TODO remove duplicated landmark in the state location.landmark and location.breakdownLocation.landmark
      //  to use location.breakdownLocation.landmark only and refactor all the references to the location.landmark
      return {
        ...state,
        landmark: action.payload || '',
        breakdownLocation: {
          ...state.breakdownLocation,
          landmark: action.payload || '',
        },
      }
    case SET_LOCATION_TYPE:
      const data = action.payload
      return {
        ...state,
        displayLandmark:
          !(data.types?.indexOf('intersection') !== -1) &&
          !state.address.startsWith(state.landmark),
      }
    case CANCEL_EDITING_REQUEST:
      // work around to keep the servicing club when call is cancelled and display
      // the correct phone number depending on club, we should move this to the servicingClub state
      return {
        ...initialState,
        step: LOCATION_STEPS.MAP,
        tempServicingClub: state.servicingClub,
      }
    case SET_LOCATION_CLUB.SUCCESS:
      return {
        ...state,
        servicingClub: action.payload.club,
        servicingClubRequested: true,
        ...(action.payload.country ? {country: action.payload.country} : {}),
      }
    case SET_LOCATION_CLUB.FAILURE:
      return {
        ...state,
        servicingClubRequested: true,
      }
    case SET_IS_HIGHWAY:
      return {
        ...state,
        highway: action.payload,
      }
    case SET_HIGHWAY_EXITS.SUCCESS:
      return {
        ...state,
        highwayExits: action.payload,
      }
    case RESET_HIGHWAY_EXITS:
      return {
        ...state,
        highway: false,
        highwayExits: null,
        servicingClub: null,
      }
    case SET_LOCATION_STEP:
      return {
        ...state,
        step: action.payload,
      }
    case SET_SPECIAL_ASSISTANCE:
      return {
        ...state,
        specialAssistance: action.payload,
      }
    case SET_LAST_SEARCH_LOCATION:
      return {
        ...state,
        lastSearchLocation: action.payload,
      }
    case RESET_LAST_SEARCH_LOCATION:
      return {
        ...state,
        lastSearchLocation: null,
      }
    default:
      return state
  }
}
