import { createInstance } from '@adobe/alloy'
import { ConfigService } from '../../config/config.service'
import { InjectionToken } from '@angular/core'

const ALLOY = 'alloy'

export const ALLOY_INSTANCE = new InjectionToken<any>(ALLOY)

export function createAlloyInstance(configService: ConfigService) {
  const alloy = createInstance({ name: ALLOY })
  alloy('configure', {
    orgId: configService.getConfig().orgId,
    datastreamId: configService.getConfig().datastreamId,
    debugEnabled: configService.getConfig().configTools,
  })
  return alloy
}
