import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core'

import { WizardRoutingModule } from './wizard-routing.module'
import { WizardComponent } from './wizard.component'
import { SharedModule } from '../../shared'
import { issueComponents } from '../issue/issue.components.declarations'
import { vehicleComponents } from '../vehicle/vehicle.components.declarations'
import { locationComponents } from '../location/location.components.declarations'
import { submitComponents } from '../submit/submit.component.declarations'

import { WizardService } from './wizard.service'
import { NgrxFormsModule } from 'ngrx-forms'

import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { LocationModule } from '../location/location.module'
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap'
import { NgxMaskDirective } from 'ngx-mask';


@NgModule({
  declarations: [
    WizardComponent,
    ...issueComponents(),
    ...vehicleComponents(),
    ...locationComponents(),
    ...submitComponents(),
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    NgrxFormsModule,
    WizardRoutingModule,
    SharedModule,
    NgxMaskDirective,
    LocationModule,
    NgbRatingModule,
  ],
  providers: [WizardService]
})
export class WizardModule {}
