import { ColorPipe } from './color.pipe'
import { PhonePipe } from './phone.pipe'
import { IssuePipe } from './issue.pipe'
import { VehiclePipe } from './vehicle.pipe'
import { SafePipe } from './safe.pipe'
import { CustomDatePipe } from './custom-date.pipe'
import { PhoneConverterPipe } from './phone-converter.pipe'
import { TimeRemainingPipe } from './time-remaining.pipe'
import { WeekdayI18nPipe } from './weekday-i18n.pipe'

export function SharedPipes() {
  return [
    ColorPipe,
    PhonePipe,
    PhoneConverterPipe,
    IssuePipe,
    SafePipe,
    VehiclePipe,
    CustomDatePipe,
    TimeRemainingPipe,
    WeekdayI18nPipe
  ]
}
