import { GoogleLocation } from '../location.types'
import {
  GOOGLE_LOCATION_TYPES,
  GoogleCoordinates,
  GOOGLE_ADDRESS_COMPONENT_TYPES,
  GeoLocationOptions,
} from './types'

export const hasUnnamedRoad = /[U-u]nnamed\s?[R-r]oad.?\s?/g
export const hasNumericRange = /^\s*[\d]+\s*-\s*[\d]+/
export const hasHighwayName = /^[A-Z]+\s?-\s?[\d]+/

const getAddressComponentByType = (
  location: GoogleLocation,
  type: GOOGLE_ADDRESS_COMPONENT_TYPES
): GoogleLocation['address_components'][0] =>
  location?.address_components.find(
    (addressComponent) => addressComponent.types.indexOf(type) > -1
  )

export const getLocationsByType = (
  allLocations: Array<GoogleLocation>,
  type: GOOGLE_LOCATION_TYPES
): Array<GoogleLocation> =>
  allLocations.filter((result) => result.geometry.location_type === type)

const getNearSameNameRoute = (
  allLocations: Array<GoogleLocation>,
  routeComponent: GoogleLocation['address_components'][0],
  locationType: GOOGLE_LOCATION_TYPES
): GoogleLocation => {
  const nearLocations = getLocationsByType(allLocations, locationType)
  const nearSameNameRoute  = nearLocations.find((location) => {
    const nearestRouteComponent = getAddressComponentByType(
      location,
      GOOGLE_ADDRESS_COMPONENT_TYPES.ROUTE
    )

    return (
      nearestRouteComponent?.short_name.toLowerCase() ===
      routeComponent.short_name.toLowerCase()
    )
  })

  return nearSameNameRoute
}

export const getRecommendedLocation = (
  allLocations: Array<GoogleLocation>,
  preferredType: GOOGLE_LOCATION_TYPES
) => {
  const locationsByType = getLocationsByType(allLocations, preferredType)
    .filter((recommendedLocation) => getAddressComponentByType(
      recommendedLocation,
      GOOGLE_ADDRESS_COMPONENT_TYPES.ROUTE
    ))

  return (
    locationsByType.find((location) =>
      hasHighwayName.test(
        getAddressComponentByType(
          location,
          GOOGLE_ADDRESS_COMPONENT_TYPES.ROUTE
        )?.short_name
      )
    ) || locationsByType[0]
  )
}

export const guessNearestLocation = (
  allLocations: Array<GoogleLocation>,
  recommendedLocation: GoogleLocation
): GoogleLocation => {
  const streetComponent = getAddressComponentByType(
    recommendedLocation,
    GOOGLE_ADDRESS_COMPONENT_TYPES.STREET_NUMBER
  )
  const routeComponent = getAddressComponentByType(
    recommendedLocation,
    GOOGLE_ADDRESS_COMPONENT_TYPES.ROUTE
  )

  if (
    (streetComponent && !hasNumericRange.test(streetComponent.short_name)) ||
    (!streetComponent &&
      routeComponent &&
      hasHighwayName.test(routeComponent.short_name))
  ) {
    return recommendedLocation
  } else if (!streetComponent && routeComponent) {
    const sharedRouteNameLocation =
      getNearSameNameRoute(
        allLocations,
        routeComponent,
        GOOGLE_LOCATION_TYPES.RANGE_INTERPOLATED
      ) ||
      getNearSameNameRoute(
        allLocations,
        routeComponent,
        GOOGLE_LOCATION_TYPES.ROOFTOP
      )

    if (sharedRouteNameLocation) {
      return sharedRouteNameLocation
    }
  }
}

export const parseLocation = (
  location: GoogleLocation,
  coords: GoogleCoordinates
): GoogleLocation => {
  if (!location) {
    throw new Error('Could not fetch location')
  }

  const addressComponents = location.address_components
    .filter(
      (item) =>
        item.types.indexOf('route') > -1 &&
        (hasUnnamedRoad.test(item.short_name) ||
          hasUnnamedRoad.test(item.long_name))
    )
    .map((unnamedRouteComponent) => ({
      ...unnamedRouteComponent,
      short_name: unnamedRouteComponent.short_name.replace(hasUnnamedRoad, ''),
      long_name: unnamedRouteComponent.long_name.replace(hasUnnamedRoad, ''),
    }))

  location.address_components[0] =
    addressComponents[0] || location.address_components[0]
  location.formatted_address = location.formatted_address?.replace(
    hasUnnamedRoad,
    ''
  )
  location.geometry.location.accuracy = coords.accuracy

  return Object.assign({}, location)
}

export const guessDefaultLocation = (
  allLocations: Array<GoogleLocation>,
  preferredType: GOOGLE_LOCATION_TYPES
): GoogleLocation => {
  const allLocationTypes = [
    GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER,
    GOOGLE_LOCATION_TYPES.RANGE_INTERPOLATED,
    GOOGLE_LOCATION_TYPES.ROOFTOP
  ].filter((type) => type !== preferredType)

  const locationsByType = allLocationTypes.reduce((locations: Array<GoogleLocation>, locationType) =>
  [
    ...locations,
    ...getLocationsByType(allLocations, locationType)
  ], [])

  return locationsByType[0]
}

export const guessLocationFromCoordsResults = (
  results: GoogleLocation[],
  coords: GoogleCoordinates,
  options: GeoLocationOptions = {
    preferredType: GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER,
    excludedComponentType: GOOGLE_ADDRESS_COMPONENT_TYPES.PLUS_CODE
  }
) => {
  const recommendedLocation = getRecommendedLocation(
    results,
    options.preferredType
  )
  const defaultLocation = guessDefaultLocation(
    results,
    options.preferredType
  )
  const plusCodeExcluded = results.filter((location) => !location.types?.includes(options.excludedComponentType))

  if (options.preferredType === GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER) {
    const bestGuess =
      guessNearestLocation(results, recommendedLocation)
      || guessNearestLocation(results, defaultLocation)
      || recommendedLocation
      || plusCodeExcluded[0]
      || results[0]

    return parseLocation(bestGuess, coords)
  } else {
    return parseLocation(recommendedLocation || defaultLocation, coords)
  }
}

export const __TEST__ = {
  getAddressComponentByType,
  getNearSameNameRoute,
}
