import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule, } from '@angular/core'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { RouterModule } from '@angular/router'
import { NgbRatingModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap'
import { NgrxFormsModule } from 'ngrx-forms'
import { ResponsiveModule } from '@viablelogic/ngx-responsive'

import { MapTouchLayerComponent } from '../modules/location/map-touch-layer/map-touch-layer.component'
import { MapComponent } from '../modules/location/map/map.component'

// UI
import { UiComponents } from '../modules/ui'
import { SharedDirectives } from './directives'
// Icons
import { icons } from './icons'
import { AgentLoginComponent } from './layouts/agent-login/agent-login.component'
import { MemberEligibilityComponent } from './member-eligibility/member-eligibility.component'
import { SharedPipes } from './pipes'
// Shared declarations
import { FormModules } from './shared-bootstraps'
// Components and providers
import { sharedComponents, sharedProviders, } from './shared.components.declarations'
import { FormModeDirective } from './layouts/white-label-credentials/form-mode.directive'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatButtonModule } from '@angular/material/button'
import { TowLocationPromoV2Component } from '../modules/location/tow-location-promo-v2/tow-location-promo-v2.component'
import { TowLocationItemComponent } from '../modules/location/tow-location-item/tow-location-item.component'
import { SeeMoreButtonComponent } from './see-more-button/see-more-button.component'
import { VehicleColorsComponent } from './vehicle/vehicle-colors/vehicle-colors.component'
import { VehicleImageComponent } from './vehicle/vehicle-image/vehicle-image.component'
import { VehicleTileComponent } from './vehicle/vehicle-tile/vehicle-tile.component'
import { VehicleHeaderBackComponent } from './vehicle-header-back/vehicle-header-back.component'
import { InputZipCodeComponent } from './controls/input-zip-code/input-zip-code.component'
import { RecaptchaModule } from 'ng-recaptcha'
import { DistanceDisplayComponent } from './distance-display/distance-display.component'
import { DRR_BASE_HREF, SHARED_CONFIG, SharedConfig } from './shared.config'
import { NgxMaskDirective } from 'ngx-mask';
import { GoogleMap, MapMarker } from '@angular/google-maps'
import { MapIconComponent } from '../modules/location/map-icon/map-icon.component';
import { ButtonComponent, CheckboxComponent, IconComponent, InputComponent, TextComponent } from '@aaa-mobile/shared-components';
import { IonicModule } from '@ionic/angular';
import { InputPostalCodeComponent } from './controls/input-postal-code/input-postal-code.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ResponsiveModule.forRoot(),
    MatDialogModule,
    FormModules(),
    NgbTypeaheadModule,
    NgrxFormsModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule,
    NgbRatingModule,
    RecaptchaModule,
    NgxMaskDirective,
    GoogleMap,
    MapMarker,
    ButtonComponent,
    TextComponent,
    IonicModule,
    InputComponent,
    IconComponent,
    CheckboxComponent,
  ],
  declarations: [
    sharedComponents(),
    SharedDirectives(),
    SharedPipes(),
    UiComponents(),
    MapTouchLayerComponent,
    MapComponent,
    MapIconComponent,
    TowLocationItemComponent,
    icons,
    AgentLoginComponent,
    MemberEligibilityComponent,
    FormModeDirective,
    VehicleHeaderBackComponent,
    SeeMoreButtonComponent,
    TowLocationPromoV2Component,
    VehicleColorsComponent,
    VehicleImageComponent,
    VehicleTileComponent,
    InputZipCodeComponent,
    InputPostalCodeComponent,
    DistanceDisplayComponent,
  ],
  exports: [
    CommonModule,
    RouterModule,
    ResponsiveModule,
    MatDialogModule,
    sharedComponents(),
    FormModules(),
    SharedDirectives(),
    SharedPipes(),
    UiComponents(),
    MapTouchLayerComponent,
    MapComponent,
    MapIconComponent,
    TowLocationItemComponent,
    icons,
    NgrxFormsModule,
    MatDividerModule,
    NgbRatingModule,
    VehicleColorsComponent,
    VehicleImageComponent,
    VehicleTileComponent,
    VehicleHeaderBackComponent,
    DistanceDisplayComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  static forRoot(config: SharedConfig): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: SHARED_CONFIG, useValue: config },
        { provide: DRR_BASE_HREF, useValue: config.drrBaseHref },
        sharedProviders(),
      ],
    }
  }
}
