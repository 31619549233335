<h1 i18n class="condensed">How many people need a ride in the tow truck?</h1>
<div class="body">
  <form id='submit-passengers' (ngSubmit)="onNext()" novalidate [ngrxFormState]="passengersForm">
    <div class="indicate-passengers_how-many">
      <app-option-selector
        [values]="_passengerOptions"
        [ngrxFormControlState]="controls?.quantity"
        (modelChange)="updatePassengers($event)"
        [tabIndex]="500"
      >
      </app-option-selector>
      <hr class="light-divider" />
    </div>

    <div class="indicate-passengers_safety">
      <label i18n>
        Safety is our top priority. If you are in an area where we offer rides in these service vehicles,
        please remember to wear a mask for the full duration of your ride.
      </label>
    </div>

    <app-button
      i18n
      type="submit"
      class="indicate-passengers_next"
      [inverted]="true"
      [disabled]="!passengersForm?.isValid"
      classNames="base-btn-size"
      [tabIndex]="515">Next</app-button>
  </form>
</div>

