import { AbstractIconComponent } from './abstract.icon'
import { Component } from '@angular/core'

@Component({
  selector: 'icon-close-header',
  templateUrl: './close-header.icon.html',
  styleUrls: ['close-header.icon.scss']
})
export class CloseHeaderIconComponent extends AbstractIconComponent {
}
