import { Action } from '@ngrx/store'
import { GoogleLocation } from '../location/location.types'

export enum StepTypes {
  ISSUE = 'issue',
  BREAKDOWN_LOCATION = 'location',
  VEHICLE = 'vehicle',
  TOWING = 'tow-location',
  SUBMIT = 'submit',
}

export type Section = VehicleSections

export enum VehicleSections {
  MAKES = 'makes',
  MODELS = 'models',
  DETAILS = 'details',
  YEARS = 'years',
  PLATE = 'plate',
  CONFIRM = 'confirm',
}

export enum TowSections {
  TOW_LOCATION = 'tow-location',
  PASSENGERS = 'passengers',
}

export enum SubmitSections {
  SUMMARY = 'summary',
}

export enum StepStatus {
  PENDING = 'PENDING',
  EDITING = 'EDITING',
  DONE = 'DONE',
  ACTIVE = 'ACTIVE',
}

export interface BaseStep {
  id: number
  status: StepStatus
}

export interface Step extends BaseStep {
  url: StepTypes
  title: string
  progress?: Array<boolean>
  sections?: Array<Section>
  afterEach?: Array<Action>
  pageType?: string
}

export enum TruckPinTypes {
  DEFAULT = 'DEFAULT',
  IN_TOW = 'IN_TOW',
  ON_LOCATION = 'ON_LOCATION',
}

export interface MarkerDetails {
  label: string
  draggable: boolean
  iconurl?: string
  active?: boolean
  showAddress?: boolean
  offsetY?: number
  offsetX?: number
  type?: MarkerTypes
  className?: string
  truckType?: TruckPinTypes
}

export enum MarkerTypes {
  BREAKDOWN = 'BREAKDOWN',
  TOW_LOCATION = 'TOW_LOCATION',
  TOWING = 'TOWING',
  TRUCK = 'TRUCK',
  SHOP = 'SHOP',
  LOCATION = 'LOCATION',
}

export enum ErrorDialogTypes {
  AUTH = 'AUTH',
  AUTH_OEM = 'AUTH_OEM',
  AUTH_VAS = 'AUTH_VAS',
  AUTH_RENTAL = 'AUTH_RENTAL',
  GENERIC = 'GENERIC',
  MEMBER_NO_MATCHES = 'MEMBER_NO_MATCHES',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  CALL_CANCEL = 'CALL_CANCEL',
  CANCEL_FEEDBACK_OPTION = 'CANCEL_FEEDBACK_OPTION',
}

export enum PromptDialogTypes {
  AAR_DETAILS = 'AAR_DETAILS',
  SHOP_DETAILS = 'SHOP_DETAILS',
  AUTHENTICATE_DIALOG = 'AUTHENTICATE_DIALOG',
  CONFIRM_CANCEL_CALL = 'CONFIRM_CANCEL_CALL',
  CONFIRM_VEHICLE_DELETE = 'CONFIRM_VEHICLE_DELETE',
  ADDITIONAL_AUTH_INFO = 'ADDITIONAL_AUTH_INFO',
  TOWING_DISTANCE_WARN = 'TOWING_DISTANCE_WARN',
  BATTERY_QUOTES = 'BATTERY_QUOTES',
  SHOW_BATTERY_QUOTES = 'SHOW_BATTERY_QUOTES',
  IFRAME_DIALOG = 'IFRAME_DIALOG',
  ADDITIONAL_LOCATION_INFO = 'ADDITIONAL_LOCATION_INFO',
  ADDITIONAL_AUTH_SEARCH = 'ADDITIONAL_AUTH_SEARCH',
  SPECIAL_ASSISTANCE_DIALOG = 'SPECIAL_ASSISTANCE_DIALOG',
  AUTH_PHONE_NUMBER_DIALOG = 'AUTH_PHONE_NUMBER_DIALOG',
  CANCEL_FEEDBACK_PROMPT = 'CANCEL_FEEDBACK_PROMPT',
  I18N_SWITCHER = 'I18N_SWITCHER',
  CONFIRM_MESSAGE_DIALOG = 'CONFIRM_MESSAGE_DIALOG',
  CLOSE_REQUEST_DIALOG = 'CLOSE_REQUEST_DIALOG'
}

export enum MessageDialogTypes {
  CUSTOM = 'CUSTOM',
  CUSTOM_PHONE = 'CUSTOM_PHONE',
  CANT_REMEMBER_MY_ID = 'CANT_REMEMBER_MY_ID',
  NOT_MEMBER = 'NOT_MEMBER',
  NOTIFY_POLICE = 'NOTIFY_POLICE',
  LOCATION_SERVICES_REQUIRED = 'LOCATION_SERVICES_REQUIRED',
  IS_NOT_WORKING = 'IS_NOT_WORKING',
  CALL_CANCELLED = 'CALL_CANCELLED',
  PARTIAL_CALL_CANCELED = 'PARTIAL_CALL_CANCELED',
  CALL_COMPLETED = 'CALL_COMPLETED',
  CALL_DUPLICATE = 'CALL_DUPLICATE',
  TOWING_DISTANCE_LIMIT = 'TOWING_DISTANCE_LIMIT',
  TOW_LOCATION_ADDRESS = 'TOW_LOCATION_ADDRESS',
  TOW_BOUNDARY_MESSAGE = 'TOW_BOUNDARY_MESSAGE',
  RAP_TOW_MILEAGE_MESSAGE = 'RAP_TOW_MILEAGE_MESSAGE',
  SESSION_TIMEOUT = 'SESSION_TIMEOUT',
  SAME_ADDRESS_TOW_BL = 'SAME_ADDRESS_TOW_BL',
  INVALID_MEMBERSHIP = 'INVALID_MEMBERSHIP',
  ADVISORY = 'ADVISORY',
  GPS_LOCATION_DISTANCE_WARNING = 'GPS_LOCATION_DISTANCE_WARNING',
}

export type ErrorDialog = DialogData & {
  type: ErrorDialogTypes
}

export type MessageDialog = DialogData & {
  type: MessageDialogTypes
}

export type PromptDialog = DialogData & {
  type: PromptDialogTypes
}

export type Dialog = DialogData & {
  type: ErrorDialogTypes | MessageDialogTypes | PromptDialogTypes
}

export interface DialogData {
  title?: string
  content?: string
  params?: any
  submit?: () => void
  close?: () => void
  open?: () => void
  closeLabel?: string
  disableClose?: boolean
  panelClass?: string
  displayedAt?: Date,
  isCallAaaButtonShown?: boolean
  scrollToTopOnClose?: boolean
}

export interface Option {
  label: string
  value?: any
}

export const DEFAULT_TRUCK_MARKER: MarkerDetails = {
  label: 'Service Vehicle',
  draggable: false,
  offsetY: 60,
  type: MarkerTypes.TRUCK
}

export const DEFAULT_BREAKDOWN_MARKER: MarkerDetails = {
  label: 'Current Location',
  draggable: false,
  offsetY: 69,
  type: MarkerTypes.BREAKDOWN,
}

export const DEFAULT_TOW_MARKER: MarkerDetails = {
  label: 'Towing Destination',
  draggable: false,
  offsetY: 72,
  type: MarkerTypes.TOW_LOCATION,
}

export const DEFAULT_TOW_DESTINATION_MARKER: MarkerDetails = {
  label: 'Towing Destination',
  draggable: false,
  offsetY: 72,
  offsetX: 25,
}

export const DEFAULT_MARKER_DETAILS: MarkerDetails = {
  label: 'Location',
  draggable: false,
  offsetY: 69,
}

export const USER_LOCATION_MARKER: MarkerDetails = {
  label: 'User Current Location',
  draggable: false,
  iconurl: 'assets/drrweb-lib/images/blue-dot.jpg',
  offsetY: 0,
  className: 'default-user-location-marker',
  type: MarkerTypes.LOCATION,
}

export interface LatLng {
  lat: number
  lng: number
  address: GoogleLocation
}

export interface MarkerLocationInfo {
  lat: number | string
  lng: number | string
  isAar?: boolean
  accuracy?: number
  address?: string
  notes?: string
  name?: string
}

export interface PageNavigationEvent {
  url: string
  title: string
  previousUrl?: string
}

export enum ABTestParams {
  LICENSE_PLATE_TO_VIN = 'lpv',
  REDESIGN = 'redesign',
}

export interface AdobeTargetProposition {
  id: string,
  scope: string,
  scopeDetails: {
   decisionProvider:  string,
   activity: {
      id: string
    },
    experience: {
      id: string
    },
    strategies: [
      {
        step: string,
        trafficType: string
      },
      {
        step: string,
        trafficType: string
      }
    ],
    correlationID: string
  },
  items: [
    {
      id: string,
      schema: string,
      meta: {}
    }
  ],
  renderAttempted: boolean
}

export interface AdobeTargetResponse {
  destinations?: [],
  inferences?: [],
  propositions?: AdobeTargetProposition[],
  decisions?: []
}

