import { AARData, COR_TYPE } from './aar/aar.types'
import { AAALocation, GenericCoordinates, GoogleLocation, LocationWithMarker, MapState, } from './location.types'
import { concatAddress } from '../../shared/utils'
import { GOOGLE_LOCATION_TYPES, GoogleCoordinates } from './google-geocode/types'
import { hasHighwayName } from './google-geocode/google-geocode.utils'
import { MemberInfo } from '../member/member.types'
import { Injectable } from '@angular/core'
import { RouteDistance } from '../route-distance/route-distance.reducer'
import { haversine } from '../../shared/haversine'
import { round } from 'lodash';
import { BROWSERS_ENUM, getBrowserDevice } from '../../shared/utils/browser-detect'

const PO_BOX_REGEX = /\b((P(ost|ostal)?([ \.]*o(ffice)?))([ \.]*Box))\b/i

export function aarAppendLocationMarker(aar: AARData): LocationWithMarker {
  const streetName = aar.address.addressLine?.substr(
    aar.address.addressLine.indexOf(' ')
  ).trim()
  const streetNumber = aar.address.addressLine?.substr(
    0,
    aar.address.addressLine.indexOf(' ')
  ).trim()

  const isCOR = Boolean(
    aar?.services?.filter((service) => service?.type?.toUpperCase() === COR_TYPE)
      .length
  )

  return {
    location: {
      id: aar.id,
      isAar: true,
      name: aar.name,
      serviceProviderCode: aar.serviceProviderCode,
      latitude: aar.latitude,
      longitude: aar.longitude,
      streetName,
      streetNumber,
      isCOR,
      city: aar.address.cityName,
      state: aar.address.stateProv.code,
      postalCode: aar.address.postalCode,
      ...( aar.address.countryName?.code ? { country: aar.address.countryName.code } : {} ),
      ...( aar.emailAddress ? { emailAddress: aar.emailAddress} : {} ),
      address: concatAddress({
        postalCode: aar.address.postalCode,
        state: aar.address.stateProv.code,
        city: aar.address.cityName,
        streetName: aar.address.addressLine,
      }),
    },
    marker: {
      lat: Number(aar.latitude),
      lng: Number(aar.longitude),
      isAar: true,
      aarId: aar.id,
      serviceProviderCode: aar.serviceProviderCode,
      address: concatAddress({
        postalCode: aar.address.postalCode,
        state: aar.address.stateProv.code,
        city: aar.address.cityName,
        streetName: aar.address.addressLine,
      }),
      name: aar.name,
    },
  }
}

export function hasHighwayNameAndGeometricCenter(
  address: string,
  googleLocation: GoogleLocation
): boolean {
  return (
    hasHighwayName.test(address) &&
    googleLocation.geometry.location_type ===
      GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER
  )
}

export function isAddressComplete(address: AAALocation): boolean {
  return Boolean(
    address &&
      address.longitude &&
      address.latitude &&
      address.state &&
      address.state.trim().length !== 0 &&
      address.city &&
      address.city.trim().length !== 0 &&
      address.streetName &&
      address.streetName.trim().length !== 0
  )
}

export function isHighwayAddressPattern(address: string): boolean {
  return hasHighwayName.test(address)
}

export function isHomeAddressComplete(memberInfo: MemberInfo): boolean {
  return Boolean(
    memberInfo &&
      isBasicAddressComplete(memberInfo.basicAddress)
      && memberInfo.city && memberInfo.city.trim().length
      && memberInfo.stateProvince && memberInfo.stateProvince.trim().length
      && memberInfo.postalCode && memberInfo.postalCode.trim().length
  )
}

export function isBasicAddressComplete(basicAddress: string): boolean {
  return Boolean(
    basicAddress &&
      basicAddress.trim().length !== 0 &&
      isNaN(+basicAddress.trim()) &&
      basicAddress.toUpperCase() !== 'SECURED' &&
      !PO_BOX_REGEX.test(basicAddress)
  )
}

export function compareAddresses(raw) {
  const lineRemove = /\n/g
  const original = raw.replace(lineRemove, ' ').toLowerCase().trim()

  return (aar) => {
    const aarAddress = aar.address.addressLine
      .replace(lineRemove, ' ')
      .toLowerCase()
      .trim()

    // strcmp style because e.g. Country can sometimes be included in one and not the other
    return (
      aarAddress.indexOf(original) === 0 || original.indexOf(aarAddress) === 0
    )
  }
}

export function isCoordsEquals(coords1: GoogleCoordinates, coords2: GoogleCoordinates) {
  if (!coords1 || !coords2) {
    return false
  }
  return coords1.lat === coords2.lat && coords1.lng === coords2.lng
}

@Injectable({
  providedIn: 'root',
})
export class LocationUtils {
  constructor() { }

  getDistance = (routesDistance: RouteDistance[], origin: GenericCoordinates, destination: GenericCoordinates) => {
    const routeDistance = routesDistance.filter(_routeDistance => this.filterRouteDistance(_routeDistance, origin, destination))
    if (routeDistance.length) {
      return routeDistance[0].distanceInMiles
    }
    return null
  }

  haversineMiles(start: GoogleCoordinates | GenericCoordinates, end: GoogleCoordinates | GenericCoordinates) {
    const precision = 3
    return round(
      haversine({
        latitude: start.lat || Number((start as GenericCoordinates).latitude),
        longitude: start.lng || Number((start as GenericCoordinates).longitude)
      }, {
        latitude: end.lat || Number((end as GenericCoordinates).latitude),
        longitude: end.lng || Number((end as GenericCoordinates).longitude)
      }, {
        unit: 'mile'
      }),
      precision
    )
  }

  convertToLocationMarker(facility: AARData) {
    return aarAppendLocationMarker(facility)
  }

  convertToMapState(map: google.maps.Map) {
    const coords = map.getCenter()
    const bounds = map.getBounds()
    return ({
      center: {
        latitude: coords.lat(),
        longitude: coords.lng(),
      },
      northEastBound: {
        latitude: bounds.getNorthEast().lat(),
        longitude: bounds.getNorthEast().lng(),
      },
      southWestBound: {
        latitude: bounds.getSouthWest().lat(),
        longitude: bounds.getSouthWest().lng(),
      },
      zoomLevel: map.getZoom()
    } as MapState)
  }

  private filterRouteDistance(routeDistance: RouteDistance, breakdown: GenericCoordinates, tow: GenericCoordinates): boolean {
    return routeDistance.origin.latitude === breakdown.latitude
      && routeDistance.origin.longitude === breakdown.longitude
      && routeDistance.destination.latitude === tow.latitude
      && routeDistance.destination.longitude === tow.longitude
  }
}

export const isInBounds = (center: GenericCoordinates, northEast: GenericCoordinates, southWest: GenericCoordinates, coverageArea: number = 1.0) => {
  const latitudeSpace = Number(northEast.latitude) - Number(southWest.latitude)
  const longitudeSpace = Number(northEast.longitude) - Number(southWest.longitude)
  const latitudeRemove = latitudeSpace * (1 - coverageArea)
  const longitudeRemove = longitudeSpace * (1 - coverageArea)
  return _isInBounds(center, {
    latitude: Number(northEast.latitude) - latitudeRemove,
    longitude: Number(northEast.longitude) - longitudeRemove,
  }, {
    latitude: Number(southWest.latitude) + latitudeRemove,
    longitude: Number(southWest.longitude) + longitudeRemove,
  })
}

const _isInBounds = (
  center: GenericCoordinates,
  northEast: GenericCoordinates,
  southWest: GenericCoordinates
) =>
  Number(northEast.latitude) >= Number(center.latitude) &&
  Number(center.latitude) >= Number(southWest.latitude) &&
  Number(northEast.longitude) >= Number(center.longitude) &&
  Number(center.longitude) >= Number(southWest.longitude);

export const convertToLocationMarker = (facility: AARData) => aarAppendLocationMarker(facility)

export const getBrowserImageDRR = () => {
  const browserType = getBrowserDevice()
  const browserImages = {
    [BROWSERS_ENUM.IOS_SAFARI]: {
      image: $localize`assets/drrweb-lib/images/prompt-ios-service-location.png`,
      cssClass: 'iosSafari'
    },
    [BROWSERS_ENUM.CHROME_DESKTOP]: {
      image: $localize`assets/drrweb-lib/images/prompt-chrome-windows-service-location.png`,
      cssClass: 'windowsChrome'
    },
    [BROWSERS_ENUM.CHROME_ANDROID]: {
      image: $localize`assets/drrweb-lib/images/prompt-android-chrome-service-location.png`,
      cssClass: 'androidChrome'
    },
    [BROWSERS_ENUM.DEFAULT]: {
      image: $localize`assets/drrweb-lib/images/prompt-default-service-location.png`,
      cssClass: 'default'
    }
  }
  return browserImages[browserType]
}

export const getBrowserImageRAP = () => {
  const browserType = getBrowserDevice()
  const browserImages = {
    [BROWSERS_ENUM.IOS_SAFARI]: {
      image: $localize`assets/drrweb-lib/images/prompt-ios-service-location_rap.png`,
      cssClass: 'iosSafari'
    },
    [BROWSERS_ENUM.CHROME_DESKTOP]: {
      image: $localize`assets/drrweb-lib/images/prompt-chrome-windows-service-location_rap.png`,
      cssClass: 'windowsChrome'
    },
    [BROWSERS_ENUM.CHROME_ANDROID]: {
      image: $localize`assets/drrweb-lib/images/prompt-android-chrome-service-location_rap.png`,
      cssClass: 'androidChrome'
    },
    [BROWSERS_ENUM.DEFAULT]: {
      image: $localize`assets/drrweb-lib/images/prompt-default-service-location.png`,
      cssClass: 'default'
    }
  }
  return browserImages[browserType]
}
