import { Component, Input, } from '@angular/core'
import { MarkerDetails } from '../../ui/ui.types';

@Component({
  selector: 'app-map-icon',
  templateUrl: './map-icon.component.html',
})
export class MapIconComponent {

  @Input() markerDetails: MarkerDetails

}
