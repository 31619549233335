<span role="alert"
      aria-atomic="true"
      [attr.aria-labelledby]="name"
      [attr.name]="name"
      [attr.aria-label]="content?.innerText"
      *ngIf="!control || (control.errors[type] && control.isTouched)">
  <aaa-text [color]="level" size="caption">
    <span class="form-helper-content">
      <span>
        <aaa-icon name="alert-triangle" size="small" [color]="level"></aaa-icon>
      </span>
      <span #content>
        <ng-content></ng-content>
      </span>
    </span>
  </aaa-text>
</span>
