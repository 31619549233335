<div class="typeahead" [ngClass]="{
    open: (onOpenClose$ | async).isOpen,
    'round-style': customElement
}">
  <div class="typeahead-wrapper">
    <ng-template #rt let-r="result" let-t="term">
      <div class="data-group no-padding"
        [ngClass]="{
          'custom-result': r.custom_result,
          'suggested-shop': r.top_template
        }">

        <ng-container *ngIf="(r.top_template); else noTemplate">
          <ng-container *ngTemplateOutlet="topTemplate">
          </ng-container>
        </ng-container>

        <ng-template #noTemplate>
          <ng-container *ngIf="(r.custom_result || r.last_searched); else defaultIcon">
            <!-- Custom result icon -->
            <ng-container *ngIf="r.custom_result">
              <app-gps-status-icon class="data-group_icon"></app-gps-status-icon>
            </ng-container>

            <!-- Last search icon -->
            <ng-container *ngIf="r.last_searched">
              <icon-clock
                class="data-group_icon"
                colorName="red">
              </icon-clock>
            </ng-container>

          </ng-container>

          <ng-template #defaultIcon>
            <icon-location
              class="data-group_icon"
              *ngIf="!inverted"
              colorName="red">
            </icon-location>
          </ng-template>

          <div class="data-group_data">
            <ngb-highlight [result]="r.main_text" [term]="t"></ngb-highlight>
            <br />
            <ngb-highlight [result]="r.secondary_text" [term]="t"></ngb-highlight>
          </div>
        </ng-template>
      </div>
    </ng-template>

    <ng-container *ngIf="(editing && !isLoading && isMobile); else defaultIcons">
      <button
        (click)="onCancelSearch()"
        [class.inverted]="inverted"
        class="clear-action-begin"
        id="back-search"
        aria-label="close">
        <icon-back></icon-back>
      </button>
    </ng-container>

    <ng-template #defaultIcons>
      <icon-location
        colorName="red"
        *ngIf="!inverted && !isLoading"
        class="typeahead_icon-begin">
      </icon-location>
    </ng-template>

    <app-loading
      width="25"
      height="25"
      *ngIf="isLoading"
      class="typeahead_icon-begin"
    ></app-loading>

    <input
      #input
      [ngClass]="{
        invalid: isValid === false,
        inverted: inverted,
        'is-invalid': searchFailed
      }"
      (focus)="typeaheadInput.nativeElement.classList.add('scroll-up')"
      (blur)="typeaheadInput.nativeElement.classList.remove('scroll-up')"
      (input)="handleInput($event)"
      (selectItem)="selectedItem($event)"
      [inputFormatter]="formatter"
      [name]="name"
      [ngbTypeahead]="search"
      [placeholder]="placeHolder"
      [attr.aria-label]="placeHolder"
      [attr.readonly]="readonly ? 'readonly' : null"
      [resultTemplate]="rt"
      [tabIndex]="tabIndex"
      [value]="model"
      class="input first-focusable-element"
      id="autocomplete-input"
      type="text"
    />
      <button class="clear-action"
        [class.inverted]="inverted"
        [hidden]="input.value.length == 0"
        (click)="onClearInput(input)"
        [name]="clearName"
        id="autocomplete-clear"
        [tabIndex]="tabIndex + 1"
        aria-label="clear">
        <icon-close></icon-close>
      </button>
      <icon-magnifier class="typeahead_search"
        [hidden]="input.value.length > 0"
        [class.inverted]="inverted">
      </icon-magnifier>

    <div class="invalid-feedback" *ngIf="searchFailed">
      Sorry, suggestions could not be loaded.
    </div>
  </div>
</div>
