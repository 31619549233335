import { GoogleLocation } from '../../location.types'
import { GOOGLE_LOCATION_TYPES, GoogleCoordinates } from '../types'

const createAddressComponent = (
  name: string,
  type: string | string[],
  shortName?: string
) => ({
  long_name: name,
  short_name: shortName ? shortName : name,
  types: Array.isArray(type) ? type : [type],
})

export const COORDS = {
  lat: -33.990614,
  lng: 151.140952,
} as GoogleCoordinates

export const IDEAL_LOCATION = {
  address_components: [
    createAddressComponent('42', 'street_number'),
    createAddressComponent('Wallaby Way', 'route'),
    createAddressComponent('Sydney', 'locality'),
    createAddressComponent('New South Wales', 'political'),
    createAddressComponent('Australia', 'country'),
    createAddressComponent('2219', 'postal_code'),
  ],
  formatted_address: '42 Wallaby Way, Sydney, NSW, mock-code, Australia',
  geometry: {
    location: COORDS,
    location_type: GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER,
  },
} as GoogleLocation

export const NO_ROUTE_AND_STREET_LOCATION = {
  address_components: [
    createAddressComponent('Lake Mary SunRail', [
      'bus_station',
      'establishment',
      'point_of_interest',
      'transit_station',
    ]),
    createAddressComponent('Lake Mary', ['locality', 'political']),
    createAddressComponent('Seminole County', [
      'administrative_area_level_2',
      'political',
    ]),
    createAddressComponent(
      'Florida',
      ['administrative_area_level_1', 'political'],
      'FL'
    ),
    createAddressComponent('United States', ['country', 'political'], 'US'),
    createAddressComponent('32746', 'postal_code'),
  ],
  formatted_address: 'Lake Mary SunRail, Lake Mary, FL 32746, USA',
  geometry: {
    location: COORDS,
    location_type: GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER,
  },
} as GoogleLocation

export const NO_STREET_NUMBER_LOCATION = {
  address_components: [
    createAddressComponent('Wallaby Way', 'route'),
    createAddressComponent('Sydney', 'locality'),
    createAddressComponent('New South Wales', 'political'),
    createAddressComponent('Australia', 'country'),
    createAddressComponent('2219', 'postal_code'),
  ],
  formatted_address: 'Wallaby Way, Sydney, NSW, mock-code, Australia',
  geometry: {
    location: COORDS,
    location_type: GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER,
  },
} as GoogleLocation

export const RANGE_STREET_NUMBER_LOCATION = {
  address_components: [
    createAddressComponent('36-42', 'street_number'),
    createAddressComponent('FL-400', 'route'),
    createAddressComponent('Sydney', 'locality'),
    createAddressComponent('New South Wales', 'political'),
    createAddressComponent('Australia', 'country'),
    createAddressComponent('2219', 'postal_code'),
  ],
  formatted_address: '36-42 Wallaby Way, Sydney, NSW, mock-code, Australia',
  geometry: {
    location: COORDS,
    location_type: GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER,
  },
} as GoogleLocation

export const STATE_LOCATION = {
  address_components: [
    createAddressComponent('FL', ['political', 'administrative_area_level_1']),
    createAddressComponent('US', ['political', 'country']),
    createAddressComponent('31000', 'postal_code'),
  ],
  formatted_address: 'FL, USA',
  geometry: {
    location: COORDS,
    location_type: GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER,
  },
} as GoogleLocation

export const COUNTRY_LOCATION = {
  address_components: [
    createAddressComponent('US', ['political', 'country']),
    createAddressComponent('31000', 'postal_code'),
  ],
  formatted_address: 'Florida',
  geometry: {
    location: COORDS,
    location_type: GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER,
  },
} as GoogleLocation

export const HIGHWAY_LOCATION = {
  address_components: [
    createAddressComponent('FL-400', 'route'),
    createAddressComponent('Sanford', 'locality'),
    createAddressComponent('FL', 'political'),
    createAddressComponent('USA', 'country'),
    createAddressComponent('31000', 'postal_code'),
  ],
  formatted_address: 'FL-400, Sanford, FL, 31000, USA',
  geometry: {
    location: COORDS,
    location_type: GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER,
  },
} as GoogleLocation

export const CURSED_LOCATION = {
  address_components: [
    createAddressComponent('Unnamed Road', 'route'),
    createAddressComponent('Sydney', 'locality'),
    createAddressComponent('New South Wales', 'political'),
    createAddressComponent('Australia', 'country'),
    createAddressComponent('2219', 'postal_code'),
  ],
  formatted_address: 'Unnamed Road, Sydney, NSW, mock-code, Australia',
  geometry: {
    location: COORDS,
    location_type: GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER,
  },
} as GoogleLocation

export const EXORCISED_LOCATION = {
  address_components: [
    createAddressComponent('', 'route'),
    createAddressComponent('Sydney', 'locality'),
    createAddressComponent('New South Wales', 'political'),
    createAddressComponent('Australia', 'country'),
    createAddressComponent('2219', 'postal_code'),
  ],
  formatted_address: 'Sydney, NSW, mock-code, Australia',
  geometry: {
    location: COORDS,
    location_type: GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER,
  },
} as GoogleLocation

export const NEARBY_INTERPOLATION = {
  address_components: [
    createAddressComponent('50', 'street_number'),
    createAddressComponent('Wallaby Way', 'route'),
    createAddressComponent('Sydney', 'locality'),
    createAddressComponent('New South Wales', 'political'),
    createAddressComponent('Australia', 'country'),
    createAddressComponent('2219', 'postal_code'),
  ],
  formatted_address: '50 Wallaby Way, Sydney, NSW, mock-code, Australia',
  geometry: {
    location: COORDS,
    location_type: GOOGLE_LOCATION_TYPES.RANGE_INTERPOLATED,
  },
} as GoogleLocation

export const NEARBY_OTHER_INTERPOLATION = {
  address_components: [
    createAddressComponent('8', 'street_number'),
    createAddressComponent('Other St', 'route'),
    createAddressComponent('Sydney', 'locality'),
    createAddressComponent('New South Wales', 'political'),
    createAddressComponent('Australia', 'country'),
    createAddressComponent('2219', 'postal_code'),
  ],
  formatted_address: '8 Other St, Sydney, NSW, mock-code, Australia',
  geometry: {
    location: COORDS,
    location_type: GOOGLE_LOCATION_TYPES.RANGE_INTERPOLATED,
  },
} as GoogleLocation

export const NEARBY_ROOFTOP = {
  address_components: [
    createAddressComponent('64', 'street_number'),
    createAddressComponent('Wallaby Way', 'route'),
    createAddressComponent('Sydney', 'locality'),
    createAddressComponent('New South Wales', 'political'),
    createAddressComponent('Australia', 'country'),
    createAddressComponent('2219', 'postal_code'),
  ],
  formatted_address: '64 Wallaby Way, Sydney, NSW, mock-code, Australia',
  geometry: {
    location: COORDS,
    location_type: GOOGLE_LOCATION_TYPES.ROOFTOP,
  },
} as GoogleLocation

export const NEARBY_OTHER_ROOFTOP = {
  address_components: [
    createAddressComponent('20', 'street_number'),
    createAddressComponent('Other St', 'route'),
    createAddressComponent('Sydney', 'locality'),
    createAddressComponent('New South Wales', 'political'),
    createAddressComponent('Australia', 'country'),
    createAddressComponent('2219', 'postal_code'),
  ],
  formatted_address: '20 Other St, Sydney, NSW, mock-code, Australia',
  geometry: {
    location: COORDS,
    location_type: GOOGLE_LOCATION_TYPES.ROOFTOP,
  },
} as GoogleLocation

export const VAGUE_LOCATION = {
  address_components: [
    createAddressComponent('Sydney', 'locality'),
    createAddressComponent('New South Wales', 'political'),
    createAddressComponent('Australia', 'country'),
    createAddressComponent('2219', 'postal_code'),
  ],
  formatted_address: 'Sydney, NSW, mock-code, Australia',
  geometry: {
    location: COORDS,
    location_type: GOOGLE_LOCATION_TYPES.APPROXIMATE,
  },
} as GoogleLocation

export const GEOMETRIC_CENTER_NO_STREET_ROUTE_COMPONENTS = {
  address_components: [
    createAddressComponent('N Donnelly St & 7th Ave', [
      'establishment',
      'point_of_interest',
      'transit_station',
    ]),
    createAddressComponent('Mount Dora', ['locality', 'political']),
    createAddressComponent('Lake County', [
      'administrative_area_level_2',
      'political',
    ]),
    createAddressComponent('Florida', [
      'administrative_area_level_1',
      'political',
    ]),
    createAddressComponent('United States', ['country', 'political']),
    createAddressComponent('32757', 'postal_code'),
  ],
  formatted_address: 'N Donnelly St & 7th Ave, Mt Dora, FL 32757, USA',
  geometry: {
    location: COORDS,
    location_type: GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER,
  },
} as GoogleLocation

export const PLUS_CODE_COMPONENT = {
  address_components: [
    createAddressComponent('PJX6+GW', [
      'plus_code',
    ]),
    createAddressComponent('Atlanta', ['locality', 'political']),
    createAddressComponent('Fulton County', [
      'administrative_area_level_2',
      'political',
    ]),
    createAddressComponent('Georgia', [
      'administrative_area_level_1',
      'political',
    ]),
    createAddressComponent('United States', ['country', 'political']),
  ],
  formatted_address: 'PJX6+GW Atlanta, GA, USA',
  geometry: {
    location: COORDS,
    location_type: GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER,
  },
  types: ['plus_code'],
} as GoogleLocation
