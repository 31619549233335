<ng-container *ngIf="isRedesign else old">
  <div>header components goes here</div>
  <div [ngSwitch]="(editingStep$ | async)?.url">
    <ng-container *ngSwitchCase="'location'">
      <app-location></app-location>
    </ng-container>
  </div>
</ng-container>
<ng-template #old>
  <section class="content-container">

    <ng-container *ngIf="(steps$ | async) as steps">
      <nav class="nav-section">
        <app-stepper [steps$]="steps$"> </app-stepper>
      </nav>
    </ng-container>

    <div role="complementary" class="mobile-wizard-container">
      <div id="wizard-content"  class="wizard-container" [ngSwitch]="(editingStep$ | async)?.url">
        <ng-container *ngSwitchCase="'location'">
          <app-location></app-location>
        </ng-container>
        <ng-container *ngSwitchCase="'issue'">
          <app-issue></app-issue>
        </ng-container>
        <ng-container *ngSwitchCase="'vehicle'">
          <app-vehicle></app-vehicle>
        </ng-container>
        <ng-container *ngSwitchCase="'submit'">
          <app-submit></app-submit>
        </ng-container>
        <ng-container *ngSwitchCase="'tow-location'">
          <app-split-panel [noPadding]="true" [showSecondaryOnMobile]="false">
            <app-tow-location-shop-list-top primary></app-tow-location-shop-list-top>
          </app-split-panel>
        </ng-container>
      </div>
    </div>
  </section>
</ng-template>
